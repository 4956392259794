import { utilities } from "@whitespace/gatsby-theme-wordpress-basic/src/foundation";
import clsx from "clsx";
import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";

import * as defaultStyles from "./EventTeaserMeta.module.css";

EventTeaserMeta.propTypes = {
  className: PropTypes.string,
  styles: PropTypes.object,
  date: PropTypes.arrayOf(PropTypes.object),
  location: PropTypes.string,
};

export default function EventTeaserMeta({
  date,
  location,
  styles = defaultStyles,
  className,
  ...restProps
}) {
  const { t } = useTranslation();

  if (!date && !location) return null;

  const startTime = date?.slice(10, 16);
  let endTime = "";
  if (date?.length === 22) {
    endTime = date?.substr(date.length - 5);
  }

  return (
    <dl className={clsx(className, styles.component)} {...restProps}>
      {startTime && (
        <div className={styles.date}>
          <dt className={utilities.visuallyHidden}>{t("Start date")}</dt>
          <dd>{startTime}</dd>
        </div>
      )}
      {endTime && (
        <div className={styles.date}>
          <dt className={utilities.visuallyHidden}>{t("End date")}</dt>
          <dd>{endTime}</dd>
        </div>
      )}
      {location && (
        <div>
          <dt className={utilities.visuallyHidden}>{t("Location")}</dt>
          <dd>{location}</dd>
        </div>
      )}
    </dl>
  );
}
