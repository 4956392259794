import { getMostRelevantDate } from "@municipio/gatsby-theme-basic/src/utils";
import formatDate from "date-fns/format";
import parseDate from "date-fns/parseJSON";
import { graphql, useStaticQuery } from "gatsby";
import { useTranslation } from "react-i18next";
import traverse from "traverse";

import { getMostRelevantEvents } from "../../../../hooks/getMostRelevantEvents";

function htmlToText(html) {
  return html && html.replace(/(<([^>]+)>)/gi, "");
}

function extractAllStrings(obj) {
  return typeof obj === "object"
    ? traverse(obj)
        .nodes()
        .filter((node) => typeof node === "string" || typeof node === "number")
        .join(" ")
    : obj;
}

function defaultContentNodeFields(source) {
  let contentType = source.contentType?.node.name;
  let dates = (source.dateGmt && [source.dateGmt]) || [];
  let date = getMostRelevantDate(dates);
  let eventsToBeNumber, datesWithTime, dateWithTime, searchDates;

  if (contentType !== "page" && source?.acfGroupEvent) {
    const {
      acfGroupEvent: { rcrRules = [], occasions = [] },
    } = source;

    const {
      eventDates,
      eventDate,
      futureEventsCount,
      eventDatesWithTime,
      eventDateWithTime,
    } = getMostRelevantEvents(occasions, rcrRules);

    if (eventDate < new Date()) {
      return null;
    }

    dates = eventDates;
    date = eventDate;
    eventsToBeNumber = futureEventsCount;
    datesWithTime = eventDatesWithTime;
    dateWithTime = eventDateWithTime;
    searchDates = datesWithTime?.map(d => d?.slice(0,10));
  }

  let filter = [];
  if (source?.acfGroupEvent) {
    if (source.acfGroupEvent.bookingLink) {
      filter.push("bookable");
    }

    // Summarize all price categories to detect if event is free of charge
    const isCustomPriceCategory =
      source.acfGroupEvent?.customPriceCategory?.length > 0;
    const customPrice = isCustomPriceCategory ? 1 : 0;

    const price = [
      source.acfGroupEvent?.priceAdult || 0,
      source.acfGroupEvent?.priceChildren || 0,
      source.acfGroupEvent?.priceInformation || 0,
      source.acfGroupEvent?.priceSenior || 0,
      source.acfGroupEvent?.priceStudent || 0,
      customPrice,
    ].reduce((prev, curr) => prev + parseInt(curr, 10), 0);
    if (price === 0) {
      filter.push("free");
    }
  }

  return {
    id: source.id,
    url: source.url || source.uri,
    contentType,
    label: source.title,
    title: source.title,
    dates,
    date,
    year: date && formatDate(parseDate(date), "yyyy"),
    month: date && formatDate(parseDate(date), "yyyy-MM"),
    years: dates && dates.map((date) => formatDate(parseDate(date), "yyyy")),
    day: searchDates,
    months:
      dates && dates.map((date) => formatDate(parseDate(date), "yyyy-MM")),
    publishDate: source.dateGmt,
    sortDate: contentType === "event" ? date.toISOString() : source.dateGmt,
    language: source.language?.slug,
    image: source.featuredImage?.node,
    text: [
      // Post content
      htmlToText(source.content),
      // Modularity modules
      source.contentArea?.modules.map(({ node: module }) => [
        // Module title if not hidden
        !module?.hideTitle && module?.title,
        // Module content (in text modules etc)
        htmlToText(module?.content),
        // Manual input in Posts module
        module?.modPostsDataSource?.data?.map((data) => [
          data.postTitle,
          htmlToText(data.postContent),
        ]),
        // Values in Contacts module
        module?.modContactsOptions?.contacts?.map(extractAllStrings),
        // Values in Fileslist module
        extractAllStrings(module?.modFileslistOptions),
        // Text in Notice module
        module?.modNoticeOptions?.noticeText,
      ]),
    ],
    acfGroupEvent: source.acfGroupEvent,
    location: source.location,
    tags: source[
      contentType === "event" ? "eventCategories" : "tags"
    ]?.nodes?.map((tag) => tag?.slug),
    filter,
    eventsToBeNumber,
    datesWithTime,
    dateWithTime,
  };
}

export default function useSearchDocuments() {
  const { i18n } = useTranslation();
  const {
    graphQlQuery: { data },
  } = useStaticQuery(graphql`
    query WPNodesForMiniSearch_MunicipioCustom {
      graphQlQuery(name: { eq: "WPContentNodesForMiniSearch" }) {
        data
      }
    }
  `);
  return data.contentNodes.nodes
    .map(defaultContentNodeFields)
    .filter((item) => item?.language === i18n.language);
}
