// extracted by mini-css-extract-plugin
export var changeView = "SearchForm-module--changeView--1Tybl";
export var clearFilter = "SearchForm-module--clearFilter--Q6WYe";
export var filterContainer = "SearchForm-module--filterContainer--lHXAc";
export var filterMulti = "SearchForm-module--filterMulti--upbnD";
export var form = "SearchForm-module--form--Du027";
export var searchHitsLabel = "SearchForm-module--searchHitsLabel--thwuK";
export var sortContainer = "SearchForm-module--sortContainer---JTUw";
export var tagSelect = "SearchForm-module--tagSelect--LBYd7";
export var toggleButtonGroupLabel = "SearchForm-module--toggleButtonGroupLabel--9ssu6";
export var toggleButtonGroupWrapper = "SearchForm-module--toggleButtonGroupWrapper--9TXja";
export var viewIcon = "SearchForm-module--viewIcon--YoWJc";