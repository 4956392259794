import usePages from "@whitespace/gatsby-theme-wordpress-basic/src/hooks/pages";
import { getPage } from "@whitespace/gatsby-theme-wordpress-basic/src/utils/pageTree";
import { useStaticQuery, graphql } from "gatsby";
import { useTranslation } from "react-i18next";

function defaultTransform(data, { pages }) {
  return data.wp.menus.nodes.map((menu) => ({
    ...menu,
    items: menu.menuItems.nodes.map((menuItem) => {
      const {
        connectedObject,
        label,
        description,
        url,
        target,
        extra,
        ...rest
      } = menuItem;

      let icon = extra?.icon || rest?.icon;
      if (typeof icon === "string") {
        icon = { name: icon };
      }

      const { contentType: { node: { name: type = "custom" } = {} } = {}, id } =
        connectedObject || {};
      const content = type === "page" ? getPage(pages, id) : {};
      return {
        type,
        url,
        target: connectedObject && connectedObject.id ? target : "_blank",
        ...content,
        label,
        description: description || (content && content.description),
        icon,
        ...rest,
      };
    }),
  }));
}

export default function useMenus({ transform = defaultTransform }) {
  const pages = usePages();
  const data = useStaticQuery(graphql`
    query WP_MenusCustom {
      wp {
        ...WP_MenusForHook
      }
    }
  `);
  return transform(data, { pages });
}

export function useMenu(location, options = {}) {
  const menus = useMenus({ ...options });
  const { i18n } = useTranslation();
  location =
    i18n.language === "sv"
      ? location
      : `${location}___${i18n.language.toUpperCase()}`;
  return menus.find((menu) => (menu.locations || []).includes(location));
}
