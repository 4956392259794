import clsx from "clsx";
import PropTypes from "prop-types";
import React from "react";

import * as defaultStyles from "./CalendarBadge.module.css";

CalendarBadge.propTypes = {
  children: PropTypes.node,
  styles: PropTypes.objectOf(PropTypes.string),
  className: PropTypes.string,
  locale: PropTypes.string,
  date: PropTypes.instanceOf(Date),
  futureEventsCount: PropTypes.number,
};

export default function CalendarBadge({
  className,
  styles = defaultStyles,
  locale,
  date,
  futureEventsCount,
  ...restProps
}) {
  const dateFormatted = date?.slice(0, 16);
  const eventDate = new Date(dateFormatted);
  const month = eventDate?.toLocaleDateString(locale, {
    month: "short",
  });
  const day = eventDate?.toLocaleDateString(locale, { day: "numeric" });

  return (
    <>
      <div className={clsx(styles.component, className)} {...restProps}>
        <div className={clsx(styles.day)} aria-hidden>
          {day}
        </div>
        <div className={clsx(styles.month)} aria-hidden>
          {month}
        </div>
      </div>
      {date && futureEventsCount > 0 && (
        <div className={clsx(styles.occasions)} aria-hidden>
          +{futureEventsCount}
        </div>
      )}
    </>
  );
}
