import { useID } from "@whitespace/components";
import { useSearchParam } from "@whitespace/gatsby-plugin-search/src/hooks";
import clsx from "clsx";
import { useFormikContext, Field } from "formik";
import { kebabCase } from "lodash-es";
import PropTypes from "prop-types";
import React from "react";

import * as styles from "./SearchFormCheckboxField.module.css";

SearchFormCheckboxField.propTypes = {
  label: PropTypes.node,
  name: PropTypes.string,
};

export default function SearchFormCheckboxField({ name, ...restProps }) {
  const generateID = useID();

  const { submitForm } = useFormikContext();
  const { paramType } = useSearchParam(name);

  let options = paramType.options;

  if (!options || Object.values(options).length === 0) {
    return null;
  }

  return (
    <div className={clsx(styles.component)} {...restProps}>
      {options.map((option, index) => {
        return (
          <div className={clsx(styles.field)} key={index}>
            <Field
              id={generateID(`${kebabCase(name)}-label-${index}`)}
              name={name}
              type="checkbox"
              value={option.value}
              onClick={() => {
                setTimeout(submitForm, 0);
              }}
              className={styles.input}
            />
            <label
              key={index}
              className={styles.label}
              htmlFor={generateID(`${kebabCase(name)}-label-${index}`)}
            >
              {option.label}
            </label>
          </div>
        );
      })}
    </div>
  );
}
