import {Icon, Link} from "@whitespace/components";
import clsx from "clsx";
import L from "leaflet";
import PropTypes from "prop-types";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Map, Marker, TileLayer} from "react-leaflet";
import {useFetch} from "use-http";

import pin from "../images/pin.svg";

import * as defaultStyles from "./LeafletMap.module.css";

LeafletMap.propTypes = {
  styles: PropTypes.objectOf(PropTypes.string),
  address: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.bool])),
  isEventPage: PropTypes.bool,
  zoomControl: PropTypes.bool,
  scrollWheelZoom: PropTypes.bool,
  marker: PropTypes.bool,
};

export default function LeafletMap({
                                     styles = defaultStyles,
                                     address,
                                     isEventPage = false,
                                     zoomControl = false,
                                     scrollWheelZoom = false,
                                     marker = true
                                   }) {
  const {city, street, zip, latitude, longitude, zoom} = address;
  const searchQuery = (longitude && latitude) ? `${latitude},${longitude}` : `${street ? street : ''} ${zip ? zip : ''} ${city ? city : ''}`;
  const [coordinates, setCoordinates] = useState([0, 0]);
  const [loading, setLoading] = useState(true);
  const {get, error} = useFetch(
    `https://nominatim.openstreetmap.org/search?q=${searchQuery}&format=json`,
  );
  const {t} = useTranslation();


  const getCoordinates = async () => {
    const response = await get();
    if (response && response.length) {
      const {lat, lon} = response[0];
      setCoordinates([Number(lat), Number(lon)]);
      setLoading(false);
    }
  };

  const useCoordinates = () => {
    setCoordinates([Number(latitude), Number(longitude)]);
    setLoading(false);
  };

  if (latitude && longitude) {
    useEffect(() => {
      useCoordinates();
    }, []);
  } else {
    useEffect(() => {
      getCoordinates();
    }, []);
  }
  if (loading) {
    return null;
  }

  if (error) {
    return null;
  }
  const usedZoom = zoom ? parseFloat(zoom, 10) : 17;

  if (typeof window !== "undefined") {
    const pinIcon = new L.Icon({iconUrl: pin, iconSize: new L.Point(32, 38)});
    return (
      <div
        className={clsx(styles.component, {
          [styles.isEventPage]: isEventPage,
        })}
      >
        <Map
          center={coordinates}
          zoom={usedZoom}
          className={styles.map}
          zoomControl={zoomControl}
          attributionControl={false}
          scrollWheelZoom={scrollWheelZoom}
        >
          <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"/>
          {marker && <Marker
            position={coordinates}
            className={styles.marker}
            icon={pinIcon}
          />}
        </Map>
        <Link
          to={`https://www.google.com/maps/search/?api=1&query=${searchQuery}`}
          target="_blank"
          showExternalIcon={false}
          className={styles.link}
        >
          <Icon name="map"/>
          {t("openMap")}
        </Link>
      </div>
    );
  }
  return null;
}
