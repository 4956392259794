import ModuleWrapper from "@municipio/gatsby-theme-basic/src/components/ModuleWrapper";
import PropTypes from "prop-types";
import React from "react";
import clsx from "clsx";

import LeafletMap from "../../../../components/LeafletMap";

import * as defaultStyles from "./MapModule.module.css";

MapModule.propTypes = {
  className: PropTypes.string,
  styles: PropTypes.objectOf(PropTypes.string),
  title: PropTypes.any,
  module: PropTypes.shape({
    modIframeOptions: PropTypes.shape({
      iframeHeight: PropTypes.number,
      iframeUrl: PropTypes.string,
    }),
  }),
};

export default function MapModule({
  styles = defaultStyles,
  className,
  title,
  module = {},
  ...restProps
}) {
  const { modMap: { url } = {} } = module;
  const slashSplits = url?.split("/");

  const coordinateParts = slashSplits.filter(part => part.startsWith("@"));
  if (coordinateParts.length === 0) {
    return null;
  }

  const latLongZoom = coordinateParts[0].replace("@", "")?.split(",");

  const address = {latitude: latLongZoom[0], longitude: latLongZoom[1], zoom: latLongZoom[2]?.replace("z", "")};

  return (
    <div className={clsx(styles.wrapper)} styles={styles}>
      <ModuleWrapper title={title} {...restProps}>
        <LeafletMap address={address} zoomControl={true} scrollWheelZoom={false} marker={false} styles={styles}/>
      </ModuleWrapper>
    </div>
  );
}
