import * as moduleComponents from "@municipio/gatsby-theme-basic/src/components/modularity-modules";
import { camelCase, upperFirst } from "lodash/fp";
import PropTypes from "prop-types";
import React from "react";

ModuleController.propTypes = {
  module: PropTypes.shape({
    contentType: PropTypes.shape({
      node: PropTypes.shape({ name: PropTypes.string }),
    }),
    hideTitle: PropTypes.bool,
    title: PropTypes.string,
  }),
  className: PropTypes.string,
};

function fromContentTypeToComponentName(contentTypeName) {
  return (
    contentTypeName &&
    upperFirst(camelCase(contentTypeName.replace(/^mod-/, ""))) + "Module"
  );
}

export default function ModuleController({ module, className }) {
  const moduleType = module?.contentType?.node?.name;
  switch (moduleType) {
    default: {
      let componentName = fromContentTypeToComponentName(moduleType);
      let Component =
        // eslint-disable-next-line import/namespace
        (componentName && moduleComponents[componentName]) ||
        // eslint-disable-next-line import/namespace
        moduleComponents.FallbackModule;
      return (
        <Component
          className={className}
          module={module}
          title={!module.hideTitle && module.title}
        />
      );
    }
  }
}
