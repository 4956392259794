import { useStaticQuery, graphql } from "gatsby";
import { useTranslation } from "react-i18next";

export default function usePages() {
  const { i18n } = useTranslation();
  let data = useStaticQuery(graphql`
    query PageTree_Falkenberg {
      graphQlQuery(name: { eq: "WPPaginatedNodesForPageTree" }) {
        data
      }
      wp {
        npRedirects(first: 10000) {
          nodes {
            id
            parentId
            title
            # isFrontPage
            uri
            menuOrder
            connectedNode {
              node {
                ... on WP_Page {
                  uri
                  ...WP_PageForMenuItem
                }
              }
            }
            # label
            # description
            # url
            # target
            ...WP_NpRedirectForPageTree
          }
        }
      }
    }
  `);

  let pages = data.graphQlQuery?.data?.pages?.nodes || [];
  let npRedirects = (data.wp?.npRedirects?.nodes || []).map(
    ({ connectedNode, ...rest }) => ({ ...rest, ...connectedNode?.node }),
  );
  let items = [...pages, ...npRedirects];
  let prefixes = new Set(i18n.languages);
  prefixes.delete("sv");
  const re = new RegExp(`^\\/(${[...prefixes].join("|")})\\/`);
  items = items.map((item) => ({
    ...item,
    language: item.language?.slug || item.uri.match(re)?.[1] || "sv",
  }));
  items = items.filter((item) => item.language === i18n.language);
  items.sort((a, b) => a.menuOrder - b.menuOrder);
  return items;
}
