import { Link, Icon } from "@whitespace/components";
import { HTML } from "@whitespace/gatsby-theme-wordpress-basic/src/components";
import FooterMenu from "@whitespace/gatsby-theme-wordpress-basic/src/components/FooterMenu";
import { layout } from "@whitespace/gatsby-theme-wordpress-basic/src/foundation";
import { usePageContext } from "@whitespace/gatsby-theme-wordpress-basic/src/hooks";
import clsx from "clsx";
import { graphql, useStaticQuery } from "gatsby";
import FooterDirectoryMenu from "gatsby-theme-falkenberg/src/components/FooterDirectoryMenu.js";
import FooterSocialMediaMenu from "gatsby-theme-falkenberg/src/components/FooterSocialMediaMenu";
import useThemeSettings from "gatsby-theme-falkenberg/src/hooks/theme";
import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";

import * as defaultStyles from "./Footer.module.css";
import * as FooterMenuStyles from "./FooterMenu.module.css";

Footer.propTypes = {
  styles: PropTypes.objectOf(PropTypes.string),
  className: PropTypes.string,
  components: PropTypes.objectOf(PropTypes.elementType),
};

const homeURLs = { sv: "/", en: "/en/" };

function DefaultLogo() {
  const {
    logotype: { logotypeNegative },
  } = useThemeSettings();

  if (logotypeNegative && logotypeNegative.fileContent) {
    return <HTML>{logotypeNegative.fileContent}</HTML>;
  }

  return null;
}

export default function Footer({
  styles = defaultStyles,
  className,
  components: { Logo = DefaultLogo } = { Logo: DefaultLogo },
  ...restProps
}) {
  const {
    t,
    i18n: { language: currentLanguage },
  } = useTranslation();
  const pageContext = usePageContext();
  let translations = pageContext?.contentNode?.translations || [];
  // let language = pageContext.contentNode.language;

  let translationLanguage = currentLanguage == "sv" ? "en" : "sv";
  let translationURL =
    translations.find(
      (translation) => translation.language.slug == translationLanguage,
    )?.uri || homeURLs[translationLanguage];

  const data = useStaticQuery(graphql`
    query FooterContent_Falkenberg {
      wp {
        default: acfOptionsFooter(language: DEFAULT) {
          footerContent {
            text
          }
        }
        sv: acfOptionsFooter(language: SV) {
          footerContent {
            text
          }
        }
        en: acfOptionsFooter(language: EN) {
          footerContent {
            text
          }
        }
      }
    }
  `);

  const text =
    data.wp?.[currentLanguage]?.footerContent?.text ||
    data.wp?.default?.footerContent?.text;

  return (
    <footer className={clsx(styles.component, className)} {...restProps}>
      <div className={clsx(layout.component, layout.componentWidthFull)}>
        <div className={styles.main}>
          <div className={styles.grid}>
            <Link className={styles.logo} to="/">
              <Logo />
            </Link>
            <FooterDirectoryMenu />
            <div className={styles.text}>
              <HTML>{text}</HTML>
            </div>
            <FooterSocialMediaMenu />
          </div>
        </div>
        <div className={styles.meta}>
          <Link
            className={clsx(styles.link, styles.locale)}
            to={translationURL}
          >
            <Icon name="globe" className={styles.globe} />
            {t(`switchLanguage.${translationLanguage}`)}
          </Link>
          <FooterMenu
            menuName={"footerLegalMenu"}
            className={clsx(styles.footerMenu)}
            styles={FooterMenuStyles}
          />
          <Link
            className={clsx(styles.link, styles.flexWrapper)}
            to="https://kommun.falkenberg.se/"
          >
            {t("gotoFalkenbergsKommun")}
            <Icon name="arrow-right" className={styles.arrow} />
          </Link>
        </div>
      </div>
      <div className={clsx(styles.bottom)}>
        <div className={clsx(layout.component, layout.componentWidthFull)}>
          <p className={styles.copyright}>
            Destination Falkenberg © {new Date().getFullYear()}{" "}
            {t("copyrightNotice")}
          </p>
        </div>
      </div>
    </footer>
  );
}
