import { Link, Icon } from "@whitespace/components";
import ArticleTitle from "@whitespace/gatsby-theme-wordpress-basic/src/components/ArticleTitle";
import { layout } from "@whitespace/gatsby-theme-wordpress-basic/src/foundation";
import { usePageContext } from "@whitespace/gatsby-theme-wordpress-basic/src/hooks";
import clsx from "clsx";
import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";

import * as defaultStyles from "./ArticleLocation.module.css";
import Map from "./LeafletMap";

ArticleLocation.propTypes = {
  styles: PropTypes.objectOf(PropTypes.string),
  className: PropTypes.string,
  menuName: PropTypes.string,
};

export default function ArticleLocation({
  styles = defaultStyles,
  className,
  ...restProps
}) {
  const { t } = useTranslation();
  const {
    contentNode: {
      location: {
        active,
        name,
        socialMedia: { facebookUrl, instagramUrl, tripadvisorUrl },
        contactDetails: { phoneNumber, siteUrl, siteUrlLabel },
        address: {
          city,
          distance,
          street,
          zip,
          showDirection,
          latitude,
          longitude,
        },
        address,
      },
    },
  } = usePageContext();
  const searchQuery =
    longitude && latitude
      ? `${latitude},${longitude}`
      : `${street ? street : ""} ${zip ? zip : ""} ${city ? city : ""}`;

  if (!active) return null;
  return (
    <div
      className={clsx(styles.component, layout.component, className)}
      {...restProps}
    >
      <div className={styles.content}>
        <div className={styles.heading}>
          <ArticleTitle customTitle={name} className={styles.title} />
          <p className={styles.address}>
            {street && <span>{street}, </span>}
            {city && <span>{city} </span>}
            {zip && <span>{zip}</span>}
          </p>

          {showDirection && street && city && (
            <Link
              className={styles.directions}
              to={`https://www.google.com/maps/search/?api=1&query=${searchQuery}`}
              target="_blank"
              showExternalIcon={false}
            >
              <Icon name="directions" className={styles.directionsIcon} />
              {t("Directions")}
            </Link>
          )}
        </div>

        <div className={styles.meta}>
          {siteUrl && (
            <div>
              <p className={styles.label}>Webbplats</p>
              <Link className={styles.website} to={siteUrl} target="_blank">
                {siteUrlLabel || siteUrl}
              </Link>
            </div>
          )}
          {phoneNumber && (
            <div>
              <p className={styles.label}>Telefonnummer</p>
              <span className={styles.phone}>{phoneNumber}</span>
            </div>
          )}

          {distance && (
            <div>
              <p className={styles.label}>Avstånd från centrum</p>
              <span className={styles.distance}>{distance}</span>
            </div>
          )}
        </div>

        <ul className={styles.socialList}>
          {facebookUrl && (
            <li className={styles.socialItem}>
              <Link
                className={styles.socialLink}
                to={facebookUrl}
                aria-label="Facebook"
                target="_blank"
                showExternalIcon={false}
              >
                <Icon name="facebook" className={styles.icon} />
              </Link>
            </li>
          )}
          {instagramUrl && (
            <li className={styles.socialItem}>
              <Link
                className={styles.socialLink}
                to={instagramUrl}
                aria-label="Instagram"
                target="_blank"
                showExternalIcon={false}
              >
                <Icon name="instagram" className={styles.icon} />
              </Link>
            </li>
          )}
          {tripadvisorUrl && (
            <li className={styles.socialItem}>
              <Link
                className={styles.socialLink}
                to={tripadvisorUrl}
                aria-label="Tripadvisor"
                target="_blank"
                showExternalIcon={false}
              >
                <Icon name="tripadvisor" className={styles.icon} />
              </Link>
            </li>
          )}
        </ul>
      </div>
      <Map address={address} />
    </div>
  );
}
