import { ensureArray } from "@whitespace/gatsby-plugin-search/src/utils";
import { sortBy, uniq } from "lodash-es";
import MiniSearch from "minisearch";
import { useCallback, useRef, useState } from "react";

const getAttribute = (attribute) => (document) => document[attribute];

function isEmpty(value) {
  if (Array.isArray(value)) {
    return value.every((value) => value == null || value === "");
  }
  return value == null || value === "";
}

function filterHits({ miniSearch, documents, query, filterParams }) {
  const filter = (hit) => {
    let filterEntries = Object.entries(filterParams)
      .filter(([, filterValue]) => !isEmpty(filterValue));

    /*
      If we filter by both from and to date we combine these to one filter condition
      so that it works well with hits that have multiple dates. Otherwise a hit could
      avoid filtering if one of the dates was after the start date and another was
      before the end date, but none of the dates were between the start and end.
    */
    const dayFilterEntries = filterEntries.filter(([key, ]) => key.startsWith("day"));
    if (dayFilterEntries.length === 2) {
      filterEntries = filterEntries.filter(([key, ]) => !key.startsWith("day"));
      const betweenFilterEntry = ["day:between", `${dayFilterEntries[0][1]}_${dayFilterEntries[1][1]}`]
      filterEntries.push(betweenFilterEntry);
    }

    return filterEntries.every(([keyAndType, filterValue]) => {
        let [key, type] = keyAndType.split(":");
        let match;
        switch (type) {
          case "gte":
            match = (hitValue, filterValue) => hitValue >= filterValue;
            break;
          case "lte":
            match = (hitValue, filterValue) => hitValue <= filterValue;
            break;
          case "between": {
            match = (hitValue, filterValue) => {
              const [first, last] = filterValue.split("_");
              return first <= hitValue && hitValue <= last;
            }
            break;
          }
          default:
            match = (hitValue, filterValue) => hitValue === filterValue;
            break;
        }
        if (Array.isArray(hit[key])) {
          if (Array.isArray(filterValue)) {
            return hit[key].some((hitValue) =>
              filterValue.some((filterValue) => match(hitValue, filterValue)),
            );
          }
          return hit[key].some((hitValue) => match(hitValue, filterValue));
        }
        if (Array.isArray(filterValue)) {
          return filterValue.some((filterValue) =>
            match(hit[key], filterValue),
          );
        }
        return match(hit[key], filterValue);
      });
  }

  // Skip minisearch altogether if there’s no query
  let hits = query
    ? miniSearch.search(query, { filter })
    : documents?.filter(filter);
  return hits;
}

export default function useMiniSearch({
  documents,
  attributesForFaceting = [],
  ...options
}) {
  const miniSearchRef = useRef();
  const [isReady, setIsReady] = useState(false);

  const addDocuments = useCallback(async (documents) => {
    await miniSearchRef.current.addAllAsync(documents);
    setIsReady(true);
  }, []);

  if (!miniSearchRef.current) {
    miniSearchRef.current = new MiniSearch(options);
    addDocuments(documents);
  }

  const facetValues = {};
  attributesForFaceting.forEach((attribute) => {
    facetValues[attribute] = uniq(
      documents
        .map(getAttribute(attribute))
        .filter((value) => value != null)
        .flatMap((value) => ensureArray(value)),
    );
  });

  const search = async (request) => {
    const {
      query,
      sortBy: sortField,
      sortOrder,
      from = 0,
      size = 20,
      ...filterParams
    } = request;

    let hits = filterHits({
      miniSearch: miniSearchRef.current,
      documents,
      query,
      filterParams,
    });

    const facets = {};
    attributesForFaceting.forEach((attribute) => {
      facets[attribute] = {};
      facetValues[attribute].filter(Boolean).forEach((value) => {
        // Make a search for each facet value as if it was set to calculate the number of hits
        facets[attribute][value] = filterHits({
          miniSearch: miniSearchRef.current,
          documents,
          query,
          filterParams: {
            ...filterParams,
            [attribute]:
              Array.isArray(filterParams[attribute]) && !Array.isArray(value)
                ? [...filterParams[attribute], value]
                : value,
          },
        })?.length;
      });
    });

    // Sorting
    if (sortField) {
      hits = sortBy(hits, sortField);
    }
    if (sortOrder === "desc") {
      hits.reverse();
    }

    let totalHits = hits.length;

    // Pagination
    hits = hits.slice(from, from + size);

    return {
      hits,
      facets,
      totalHits,
    };
  };

  return { isReady, search };
}
