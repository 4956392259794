import { Icon } from "@whitespace/components";
import * as styles from "@whitespace/gatsby-plugin-search/src/components/SearchForm.module.css";
import SearchFormField from "@whitespace/gatsby-plugin-search/src/components/SearchFormField";
import { useSearch } from "@whitespace/gatsby-plugin-search/src/hooks";
import { usePageContext } from "@whitespace/gatsby-theme-wordpress-basic/src/hooks";
import clsx from "clsx";
import { Formik, Form } from "formik";
import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";

SearchForm.propTypes = {
  className: PropTypes.string,
  showHitsTotal: PropTypes.bool,
  gridView: PropTypes.bool,
  setGridView: PropTypes.func,
};

export default function SearchForm({
  className,
  showHitsTotal = true,
  setGridView,
  gridView,
  ...props
}) {
  const { params, setParams, schema, totalHits } = useSearch();
  const { t } = useTranslation();
  const pageContext = usePageContext();
  const eventsArchive = pageContext?.contentType?.name === "event";

  return (
    <Formik
      initialValues={params}
      enableReinitialize={true}
      validationSchema={schema}
      onSubmit={async (values) => {
        setParams({ ...values, page: null });
      }}
      {...props}
    >
      {({ setFieldValue, submitForm }) => (
        <Form className={clsx(styles.form, className)}>
          <SearchFormField param="query" />
          <SearchFormField param="contentType" />
          <div className={styles.filterContainer}>
            <div className={styles.tagSelect}><SearchFormField
              param="tags"
              placeholder={t(`search.facetLabels.tags.placeholder`)}
            />
            </div>
            <SearchFormField param="day" />
            <SearchFormField param="filter" />
            {eventsArchive && (
              <button
                className={clsx(styles.clearFilter, styles.changeView)}
                onClick={() => setGridView(!gridView)}
                type="button"
              >
                <Icon className={styles.viewIcon} name="apps-list-detail" />
                {t("changeView")}
              </button>
            )}

            {!!(
              params.tags?.length ||
              params.date?.length ||
              params.filter?.length
            ) && (
              <button
                className={styles.clearFilter}
                onClick={() => {
                  setFieldValue("tags", null);
                  setFieldValue("date", null);
                  setFieldValue("filter", null);
                  setTimeout(submitForm, 0);
                }}
                type="button"
              >
                {t("clearFilterLabel")}
              </button>
            )}
          </div>
        </Form>
      )}
    </Formik>
  );
}
