export default function useFlatListToHierarchical(
  data = [],
  { idKey = "id", parentKey = "menuParentId", childrenKey = "children" } = {},
) {
  const tree = [];
  const childrenOf = {};
  data.forEach((item) => {
    const newItem = { ...item };
    const { [idKey]: id, [parentKey]: menuParentId = 0 } = newItem;
    childrenOf[id] = childrenOf[id] || [];
    newItem[childrenKey] = childrenOf[id];
    menuParentId
      ? (childrenOf[menuParentId] = childrenOf[menuParentId] || []).push(
          newItem,
        )
      : tree.push(newItem);
  });
  return tree;
}
