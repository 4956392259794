import { useModularityModule } from "@municipio/gatsby-theme-basic/src/hooks";
import { withComponentDefaults } from "@whitespace/components";
import clsx from "clsx";
import PropTypes from "prop-types";
import React from "react";

import * as defaultStyles from "./PostsModuleHeader.module.css";

PostsModuleHeader.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  components: PropTypes.objectOf(PropTypes.elementType),
  icon: PropTypes.object,
  styles: PropTypes.objectOf(PropTypes.string),
};

export default withComponentDefaults(PostsModuleHeader, "postsModuleHeader");

function PostsModuleHeader({
  children,
  className,
  styles = defaultStyles,
  ...restProps
}) {
  const { module } = useModularityModule();
  const excerpt = module.modPostsExcerpt?.excerpt;

  return (
    <div className={clsx(styles.component, className)} {...restProps}>
      {children}
      {!!excerpt && <p className={styles.excerpt}>{excerpt}</p>}
    </div>
  );
}
