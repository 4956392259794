import { Link, Icon } from "@whitespace/components";
import { useMenu } from "@whitespace/gatsby-theme-wordpress-basic/src/hooks/menus";
import clsx from "clsx";
import { snakeCase } from "lodash";
import PropTypes from "prop-types";
import React from "react";

import useFlatListToHierarchical from "../hooks/flatListToHierarchical";

import * as defaultStyles from "./FooterDirectoryMenu.module.css";

FooterDirectoryMenu.propTypes = {
  styles: PropTypes.objectOf(PropTypes.string),
  className: PropTypes.string,
  menuName: PropTypes.string,
};

export default function FooterDirectoryMenu({
  styles = defaultStyles,
  menuName = "directory",
  className,
  ...restProps
}) {
  const menu = useMenu(snakeCase(menuName).toUpperCase());
  const items = useFlatListToHierarchical(menu?.items);

  if (!items?.length) {
    return null;
  }

  return (
    <nav className={clsx(styles.component, className)} {...restProps}>
      {items.map((item, index) => (
        <div className={styles.gridItem} key={index}>
          <Link className={styles.listHeading} to={item.url}>
            {item.icon && (
              <Icon name={item.icon.name} className={styles.icon} />
            )}
            {item.label}
          </Link>
          <ul className={styles.list}>
            {item.children.map((item, index) => (
              <li key={index} className={styles.item}>
                <Link className={styles.link} to={item.url}>
                  {item.label}
                </Link>
              </li>
            ))}
          </ul>
        </div>
      ))}
    </nav>
  );
}
