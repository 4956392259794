import { Global } from "@emotion/react";
import Color from "color";
import { graphql, useStaticQuery } from "gatsby";
import { kebabCase, transform } from "lodash";
import PropTypes from "prop-types";
import React from "react";

BrandColorWrapper.propTypes = {
  children: PropTypes.node,
};

export default function BrandColorWrapper({ children }) {
  const colorItems =
    useStaticQuery(graphql`
      query BrandColorsCustom {
        wp {
          ...WP_BrandColorsForHook
        }
      }
    `).wp.acfOptionsThemeOptions?.colorScheme?.brandColors || [];

  const colors = transform(
    colorItems,
    (colors, { key, value }) => (colors[key] = value),
    {},
  );

  const regex = /linear-gradient\((.*)\)/;
  const vars = Object.entries(colors).reduce((acc, [key, value]) => {
    acc[`--brand-color-${kebabCase(key)}`] = value;
    if (!value.includes("linear-gradient")) {
      const color = Color(value);
      acc[`--brand-color-${kebabCase(key)}-text`] = color.isDark(value)
        ? "white"
        : "black";
      acc[`--brand-color-${kebabCase(key)}-hover`] = value + "99";
    } else {
      /*
        If the color is a gradient we want to find all the hex colors and add an opacity value to them
        to create the hover background color.
      */
      const trim = value.match(regex)[1];
      const splits = trim.split(" ");

      for (let i = 0; i < splits.length; i++) {
          const part = splits[i];
          if (part.startsWith("#")) {
            splits[i] = part + "99" + (part.endsWith(",") ? "," : "");
          }
      }

      acc[`--brand-color-${kebabCase(key)}-hover`] = `linear-gradient(${splits.join(" ")})`;
    }
    return acc;
  }, {});

  return (
    <>
      <Global
        styles={{
          ":root": vars,
        }}
      />
      {children}
    </>
  );
}
