// extracted by mini-css-extract-plugin
export var address = "ArticleLocation-module--address--OMIAR";
export var component = "ArticleLocation-module--component--Hngyu";
export var content = "ArticleLocation-module--content--jRTsa";
export var directions = "ArticleLocation-module--directions--ynhH+";
export var directionsIcon = "ArticleLocation-module--directionsIcon--38MEN";
export var eyebrow = "ArticleLocation-module--eyebrow--NiTX0";
export var label = "ArticleLocation-module--label--v2AAI";
export var meta = "ArticleLocation-module--meta--y2O17";
export var socialItem = "ArticleLocation-module--socialItem--Wvs03";
export var socialLink = "ArticleLocation-module--socialLink--lnoPw";
export var socialList = "ArticleLocation-module--socialList--k2C8t";
export var title = "ArticleLocation-module--title--T9gAm";