// extracted by mini-css-extract-plugin
export var button = "NewsletterModule-module--button--X7sNw";
export var component = "NewsletterModule-module--component--H8CET";
export var emailLabel = "NewsletterModule-module--emailLabel--FXi4T";
export var form = "NewsletterModule-module--form--Owe8P";
export var formField = "NewsletterModule-module--formField--5Qpdl";
export var formInput = "NewsletterModule-module--formInput--zRih7";
export var isCompact = "NewsletterModule-module--isCompact--mpMbs";
export var link = "NewsletterModule-module--link--IwF5l";
export var text = "NewsletterModule-module--text--Xi4pC";
export var title = "NewsletterModule-module--title--ZG2E3";