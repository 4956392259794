import ModuleWrapper from "@municipio/gatsby-theme-basic/src/components/ModuleWrapper";
import { Button, FormInputField } from "@whitespace/components";
import { useComponentSize } from "@whitespace/gatsby-hooks";
import clsx from "clsx";
import { Formik } from "formik";
import PropTypes from "prop-types";
import React, { useRef } from "react";
import { useTranslation } from "react-i18next";

import * as defaultStyles from "./NewsletterModule.module.css";

NewsletterModule.propTypes = {
  className: PropTypes.string,
  styles: PropTypes.objectOf(PropTypes.string),
  title: PropTypes.any,
  module: PropTypes.any,
};

export default function NewsletterModule({
  styles = defaultStyles,
  title,
  module: { modNewsletterOptions: { text, link: { url } } = {} },
  ...restProps
}) {
  const { t } = useTranslation();

  const componentRef = useRef(null);
  let { width: componentWidth } = useComponentSize(componentRef);
  const isCompact = componentWidth <= 340;

  const location = window.location.origin;
  const updatedUrl = url.split("/").slice(3).join("/");
  const urlForThankYouPage = location + "/" + updatedUrl;

  return (
    <ModuleWrapper
      as={"div"}
      styles={styles}
      className={clsx(styles.component, isCompact && styles.isCompact)}
      {...restProps}
    >
      <div ref={componentRef}>
        <h2 className={styles.title}>{title}</h2>
        <p className={styles.text}>{text}</p>
        <Formik
          initialValues={{
            email: "",
          }}
        >
          <form
            name="fpren"
            action="https://public.paloma.se/subscription/register"
            method="post"
            className={styles.form}
          >
            <input type="hidden" name="FormKey" value="ea6f0441-7912-4dac-9aef-ac35f8f78689" />
            <input type="hidden" name="Language" value="sv" />
            <input type="hidden" name="LegalBasis" value="1" />
            <input type="hidden" name="TermsGuid" value="d39157e2-fe87-4226-8d10-afcbf1cb79de" />
            <input type="hidden" name="Lists" value="bfaf2ef9-1991-4a6f-a284-93e41411b812" />
            <input type="hidden" name="OptInSenderName" value="Falkenberg" />
            <input type="hidden" name="OptInSenderEmail" value="destinationfalkenberg@falkenberg.se" />
            <input type="hidden" name="OptInMessage" value="SGVqITxici8+PGJyLz5EdSBm5XIgZGV0dGEgbWVkZGVsYW5kZSBlZnRlcnNvbSBkaW4gZS1wb3N0IHJlZ2lzdHJlcmFkZXMgdmlhIHblcnQgcmVnaXN0cmVyaW5nc2Zvcm11bORyLjxici8+PGJyLz5G9nIgYXR0IGJla3LkZnRhIGRldHRhIG9jaCBhbm3kbGEgZGluIGFkcmVzcyA8YSBocmVmPSJbLy9hY3RpdmF0aW9udXJsXSI+a2xpY2thIGjkcjwvYT4sIGVsbGVyIGtsaXN0cmEgaW4gZGV0dGEgaSBkaW4gd2ViYmzkc2FyZTo8YnIvPlsvL2FjdGl2YXRpb251cmxdPGJyLz48YnIvPk9tIGR1IGludGUgYmVzdORsbHQgZGV0dGEga2FuIGR1IGJhcmEgYm9ydHNlIGZy5W4gZGV0dGEgbWVkZGVsYW5kZSBz5SBrb21tZXIgaW5nZW4gcmVnaXN0cmVyaW5nIGF0dCBza2UuPGJyLz4=" />
            <input type="hidden" name="ThanksPage" value={urlForThankYouPage} />
            <input type="hidden" name="CheckThanksPageAvailability" value="0" />

            <div className={styles.formField}>
              <label className={styles.emailLabel}>{t("yourEmail")}</label>
              <FormInputField
                name={"email"}
                f
                type={"email"}
                inputProps={{
                  placeholder: t("emailExample"),
                }}
                required={true}
                className={styles.formInput}
              />
              <Button className={styles.button} type="submit">
                {t("subscribe")}
              </Button>
            </div>
            <div className={styles.formField}>
              <input required={true} type={"checkbox"} name={"haschecked"} />
              <label>
                {t("agreeTo")}
                <a
                  href="https://public.paloma.se/Consent/ReadConsent?termsguid=d39157e2-fe87-4226-8d10-afcbf1cb79de"
                  target="_blank"
                  rel="noreferrer"
                  className={styles.link}
                >
                  {t("processingOfData")}
                </a>
              </label>
            </div>
          </form>
        </Formik>
      </div>
    </ModuleWrapper>
  );
}
