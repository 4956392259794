import { Grid } from "@municipio/gatsby-theme-basic/src/components";
import { useSearch } from "@whitespace/gatsby-plugin-search/src/hooks";
import clsx from "clsx";
import { camelCase, upperFirst } from "lodash-es";
import PropTypes from "prop-types";
import React from "react";

import * as hitComponents from "./search-hits";
import * as defaultStyles from "./SearchHits.module.css";

SearchHits.propTypes = {
  styles: PropTypes.objectOf(PropTypes.string),
  hits: PropTypes.array.isRequired,
  gridView: PropTypes.bool.isRequired,
};

DefaultComponent.propTypes = {
  children: PropTypes.array,
  gridView: PropTypes.bool.isRequired,
};

function getComponentFromContentType(contentType) {
  let componentName =
    contentType && `${upperFirst(camelCase(contentType))}SearchHit`;
  return (
    // eslint-disable-next-line import/namespace
    (componentName && hitComponents[componentName]) ||
    hitComponents.DefaultSearchHit
  );
}

function DefaultComponent({ children, gridView, ...restProps }) {
  if (!gridView) {
    return <ul {...restProps}>{children}</ul>;
  }
  return <Grid {...restProps}>{children}</Grid>;
}

export default function SearchHits({ styles = defaultStyles, hits, gridView }) {
  const { schema, forcedParams } = useSearch();
  return (
    <DefaultComponent
      grid={Object.keys(forcedParams).length}
      className={clsx(styles.wrapper, gridView && styles.grid)}
      autoFit={!schema}
      gridView={gridView}
    >
      {hits.map((hit, index) => {
        const Component = getComponentFromContentType(hit.contentType);
        return <Component hit={hit} key={index} gridView={gridView} />;
      })}
    </DefaultComponent>
  );
}
