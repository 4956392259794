import { Link, Icon } from "@whitespace/components";
import { useMenu } from "@whitespace/gatsby-theme-wordpress-basic/src/hooks/menus";
import clsx from "clsx";
import { snakeCase } from "lodash";
import PropTypes from "prop-types";
import React from "react";

import useFlatListToHierarchical from "../hooks/flatListToHierarchical";

import * as defaultStyles from "./FooterSocialMediaMenu.module.css";

FooterSocialMediaMenu.propTypes = {
  styles: PropTypes.objectOf(PropTypes.string),
  className: PropTypes.string,
  menuName: PropTypes.string,
};

export default function FooterSocialMediaMenu({
  styles = defaultStyles,
  menuName = "footerSocialMedia",
  className,
  ...restProps
}) {
  const menu = useMenu(snakeCase(menuName).toUpperCase());
  const items = useFlatListToHierarchical(menu?.items);

  if (!items?.length) {
    return null;
  }

  return (
    <nav className={clsx(styles.component, className)} {...restProps}>
      {items.map((item, index) => (
        <Link className={styles.link} to={item.url} key={index}>
          {item.icon && <Icon name={item.icon.name} className={styles.icon} />}
          {item.label}
        </Link>
      ))}
    </nav>
  );
}
