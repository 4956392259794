import { css } from "@emotion/react";
import { useComponentSize } from "@whitespace/gatsby-hooks";
import clsx from "clsx";
import PropTypes from "prop-types";
import React, {
  Children,
  Fragment,
  useMemo,
  useRef,
  useLayoutEffect,
} from "react";

import * as defaultStyles from "./Grid.module.css";

function useComputedCSSGridColumns(ref, deps) {
  const { width } = useComponentSize(ref);
  return useMemo(() => {
    if (
      typeof window === "undefined" ||
      !window.getComputedStyle ||
      !ref.current
    ) {
      return;
    }
    let { gridTemplateColumns } = window.getComputedStyle(ref.current);
    return gridTemplateColumns.split(/\s+/g).map((width) => ({
      width: parseFloat(width),
    }));
  }, [width, ...deps]);
}

function useComputedCSSGridGap(ref) {
  const { width } = useComponentSize(ref);
  return useMemo(() => {
    if (
      typeof window === "undefined" ||
      !window.getComputedStyle ||
      !ref.current
    ) {
      return;
    }
    let { gap } = window.getComputedStyle(ref.current);
    const [rowGap, columnGap] = gap.split(" ");
    return {
      row: parseInt(rowGap, 10),
      column: parseInt(columnGap, 10),
    };
  }, [width]);
}

Grid.propTypes = {
  as: PropTypes.elementType,
  autoFit: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
  columnMinWidth: PropTypes.number,
  components: PropTypes.objectOf(PropTypes.elementType),
  gap: PropTypes.number,
  styles: PropTypes.objectOf(PropTypes.string),
};

export default function Grid({
  as: Wrapper = "ul",
  autoFit = false,
  children,
  className,
  columnMinWidth,
  components: { ItemWrapper = null } = { ItemWrapper: null },
  gap,
  styles = defaultStyles,
  ...restProps
}) {
  const ref = useRef();
  const itemsRef = useRef([]);
  const columns = useComputedCSSGridColumns(ref, [
    columnMinWidth,
    gap,
    className,
    JSON.stringify(styles),
  ]);
  const gaps = useComputedCSSGridGap(ref);
  if (ItemWrapper == null) {
    ItemWrapper = Wrapper === "ul" || Wrapper === "ol" ? "li" : Fragment;
  }
  const items =
    typeof children === "function" ? children({ columns }) : children;

  function setMasonryRowOffsets() {
    if (!itemsRef.current) return;

    itemsRef.current = itemsRef.current.filter(Boolean);

    itemsRef.current.forEach((column) => {
      if (column) {
        // Reset offset
        column.style.removeProperty("--grid-masonry-gap");
      }
    });
    itemsRef.current.slice(columns.length).forEach((item, index) => {
      if (item) {
        // Set offsets
        const prev = itemsRef.current[index].getBoundingClientRect().bottom;
        const curr = item.getBoundingClientRect().top;
        item.style.setProperty(
          "--grid-masonry-gap",
          `${prev + gaps.row - curr}px`,
        );
      }
    });
  }

  useLayoutEffect(() => {
    if (!columns) return;
    setMasonryRowOffsets();
    window.addEventListener("resize", setMasonryRowOffsets);
    return () => window.removeEventListener("resize", setMasonryRowOffsets);
  }, [items, columns, gaps]);

  return (
    <Wrapper
      ref={ref}
      className={clsx(styles.component, styles.list, className)}
      css={css({
        "--grid-column-min-width": columnMinWidth,
        "--grid-gap": gap,
        "--grid-repeat": autoFit ? "auto-fit" : null,
      })}
      {...restProps}
    >
      {Children.map(items, (child, index) => {
        return (
          child && (
            <ItemWrapper
              key={index}
              ref={(el) => (itemsRef.current[index] = el)}
              className={styles.item}
            >
              {child}
            </ItemWrapper>
          )
        );
      })}
    </Wrapper>
  );
}
