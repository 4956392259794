import {
  Teaser as TeaserWrapper,
  TeaserContent,
  TeaserMeta,
  TeaserTitle,
  TeaserMedia,
  Icon,
} from "@whitespace/components";
import { Time } from "@whitespace/gatsby-theme-wordpress-basic/src/components";
import clsx from "clsx";
import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";

import * as defaultStyles from "./Teaser.module.css";

Teaser.propTypes = {
  className: PropTypes.string,
  styles: PropTypes.objectOf(PropTypes.string),
  contentNode: PropTypes.shape({
    contentType: PropTypes.string,
    content: PropTypes.node,
    date: PropTypes.string,
    excerpt: PropTypes.node,
    image: PropTypes.object,
    theme: PropTypes.string,
    title: PropTypes.node,
    url: PropTypes.string,
    taxonomies: PropTypes.arrayOf(PropTypes.object),
    link: PropTypes.object,
  }),
  dateFormat: PropTypes.objectOf(PropTypes.string),
};

export default function Teaser({
  className,
  styles = defaultStyles,
  contentNode,
  dateFormat = {
    year: "numeric",
    month: "numeric",
    day: "numeric",
  },
  ...restProps
}) {
  const { t } = useTranslation();
  const { date, excerpt, image, title, url, link, contentType } = contentNode;
  const target = link?.target;
  const linkTitle = link?.title || t("Read more");

  return (
    <TeaserWrapper
      className={clsx(className, styles.component)}
      link={{ url, target }}
      styles={styles}
      {...restProps}
    >
      {image && (
        <TeaserMedia image={image} styles={styles} aspectRatio={16 / 10} />
      )}
      <TeaserContent styles={styles}>
        <TeaserTitle>{title}</TeaserTitle>
        {excerpt && <p className={clsx(styles.excerpt)}>{excerpt}</p>}
        {url && contentType === "page" && (
          <span className={styles.cta}>{linkTitle}</span>
        )}
        {url && contentType !== "page" && (
          <a href={url} className={clsx(styles.cta, styles.link)}>
            {linkTitle}
            <Icon className={styles.icon} name="external" />
          </a>
        )}
      </TeaserContent>
    </TeaserWrapper>
  );
}
