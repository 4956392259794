// extracted by mini-css-extract-plugin
export var bookingWrapper = "EventInfo-module--bookingWrapper--XNmCX";
export var buyBtn = "EventInfo-module--buyBtn--oJxuA";
export var component = "EventInfo-module--component--U0M-k";
export var exeption = "EventInfo-module--exeption--qP483";
export var grid = "EventInfo-module--grid--ZyTeE";
export var gridItem = "EventInfo-module--gridItem--KTg3j";
export var inner = "EventInfo-module--inner--BiQem";
export var list = "EventInfo-module--list--2vFO8";
export var rcrEvent = "EventInfo-module--rcrEvent--ihPUL";
export var table = "EventInfo-module--table--7+EvF";
export var td = "EventInfo-module--td--Ef9R-";
export var tr = "EventInfo-module--tr--IOb+P";