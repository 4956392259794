import CookieConsentProvider from "@whitespace/gatsby-plugin-cookie-consent/src/CookieConsentProvider";
import React, { createContext } from "react";

export const pageWrapperContext = createContext();
export const PageWrapperContextProvider = pageWrapperContext.Provider;

export default function PageWrapper({ children }) {
  return (
    <>
      <CookieConsentProvider>{children}</CookieConsentProvider>
    </>
  );
}
