import ModuleWrapper from "@municipio/gatsby-theme-basic/src/components/ModuleWrapper";
import { Icon } from "@whitespace/components";
import { useStaticQuery, graphql } from "gatsby";
import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";

import * as defaultStyles from "./WeatherModule.module.css";

WeatherModule.propTypes = {
  className: PropTypes.string,
  styles: PropTypes.objectOf(PropTypes.string),
  title: PropTypes.any,
  module: PropTypes.any,
};

const getIconName = (icon) => {
  let iconName = icon;
  if (icon.includes("_")) {
    iconName = icon.split("_")?.[0];
  }
  return iconName;
};

export default function WeatherModule({
  styles = defaultStyles,
  title,
  ...restProps
}) {
  const { t } = useTranslation();

  const {
    allWeather: { nodes },
  } = useStaticQuery(graphql`
    query WeatherQuery {
      allWeather {
        nodes {
          id
          airPressure
          airTemperature
          windSpeed
          windDirection
          waterTemperature
          waterFlow
          icon
          updatedAt
        }
      }
    }
  `);

  const {
    id,
    airPressure,
    airTemperature,
    windSpeed,
    windDirection,
    waterTemperature,
    waterFlow,
    icon,
    updatedAt,
  } = nodes[0];

  if (id === "-1") {
    return <p>{t("weather.notShowing")}</p>;
  }

  let wind;
  if (windDirection > 0 && windDirection < 45) {
    wind = t("wind.windNorth");
  } else if (windDirection > 45 && windDirection < 135) {
    wind = t("wind.windEast");
  } else if (windDirection > 135 && windDirection < 225) {
    wind = t("wind.windSouth");
  } else if (windDirection > 225 && windDirection < 315) {
    wind = t("wind.windWest");
  } else {
    wind = t("wind.windNorth");
  }
  const updated = updatedAt?.split("T")?.[0];
  const iconName = getIconName(icon);
  return (
    <ModuleWrapper
      as={"div"}
      styles={styles}
      className={styles.component}
      {...restProps}
    >
      <div className={styles.wrapper}>
        <div className={styles.header}>
          <img
            src={`/icons/weather/${icon}.svg`}
            className={styles.img}
            alt=""
          />
          <div className={styles.headerText}>
            <h2 className={styles.title}>{title}</h2>
            <p className={styles.description}>{t(`weather.${iconName}`)}</p>
          </div>
        </div>
        <div className={styles.weatherText}>
          <div className={styles.weather}>
            <Icon name="termometer" className={styles.icon} />
            <div>
              <p>
                <span className={styles.text}>
                  {t("weatherModuleTitles.airTemp")}:{" "}
                </span>
                {airTemperature} °C
              </p>
              <p>
                <span className={styles.text}>
                  {t("weatherModuleTitles.waterTemp")}:{" "}
                </span>
                {waterTemperature} °C
              </p>
              <p>
                <span className={styles.text}>
                  {t("weatherModuleTitles.waterFlow")}:{" "}
                </span>
                {waterFlow} m³/s
              </p>
              <p>
                <span className={styles.text}>
                  {t("weatherModuleTitles.airPressure")}:{" "}
                </span>
                {airPressure} hPa
              </p>
            </div>
          </div>
          <div className={styles.weather}>
            <Icon name="wind" className={styles.icon} />
            <div>
              <p>
                <span className={styles.text}>
                  {t("weatherModuleTitles.wind")}:{" "}
                </span>
                <span className={styles.number}>
                  {wind} {windSpeed} m/s
                </span>
              </p>
            </div>
          </div>
        </div>
        <div className={styles.bottomText}>
          <p>
            {t("weatherModuleTitles.lastUpdated")}: {updated}
          </p>
          <a
            href={
              "https://www.yr.no/nb/v%C3%A6rvarsel/daglig-tabell/2-2715573/Sweden/Halland/Falkenbergs%20Kommun/Falkenberg"
            }
          >
            {t("weatherModuleTitles.info")}
          </a>
        </div>
      </div>
    </ModuleWrapper>
  );
}
