import { useSearch } from "@whitespace/gatsby-plugin-search/src/hooks";
import PropTypes from "prop-types";
import React from "react";

import EventTeaser from "../../../../components/EventTeaser";

EventSearchHit.propTypes = {
  dateFormat: PropTypes.objectOf(PropTypes.string),
  hit: PropTypes.object,
  gridView: PropTypes.bool,
};

export default function EventSearchHit({
  dateFormat,
  hit,
  gridView,
  ...restProps
}) {
  void dateFormat;
  const { forcedParams } = useSearch();
  return (
    <EventTeaser
      as={Object.keys(forcedParams).length ? "div" : "li"}
      contentNode={hit}
      gridView={gridView}
      {...restProps}
    />
  );
}
