module.exports = [{
      plugin: require('../../../node_modules/@whitespace/gatsby-plugin-page-wrapper/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/@whitespace/gatsby-plugin-i18next/gatsby-browser.js'),
      options: {"plugins":[],"defaultLanguage":"sv","languages":["sv","en"]},
    },{
      plugin: require('../../../node_modules/@whitespace/gatsby-theme-wordpress-basic/gatsby-browser.js'),
      options: {"plugins":[],"basePath":"/home/vsts/work/1/s/projects/lax.se","fragmentsDir":"/home/vsts/work/1/s/projects/lax.se/src/fragments","i18next":{"defaultLanguage":"sv","languages":["sv","en"]},"wp":{"url":"https://cms.falkenberg.se/salmon","nodesPerFetch":150,"contentTypes":{"page":{}}},"search":{"paths":["sok","en/search"]},"postCss":{"postcssOptions":{"plugins":[null]}},"compileES6Packages":{"modules":["@whitespace/components"]}},
    },{
      plugin: require('../../../node_modules/@municipio/gatsby-theme-basic/gatsby-browser.js'),
      options: {"plugins":[],"basePath":"/home/vsts/work/1/s/projects/lax.se","fragmentsDir":"/home/vsts/work/1/s/projects/lax.se/src/fragments","i18next":{"defaultLanguage":"sv","languages":["sv","en"]},"wp":{"url":"https://cms.falkenberg.se/salmon","nodesPerFetch":150,"contentTypes":{"page":{}}},"search":{"paths":["sok","en/search"]},"postCss":{"postcssOptions":{"plugins":[null]}},"compileES6Packages":{"modules":["@whitespace/components"]}},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Falkenberg","short_name":"Falkenberg","start_url":"/","background_color":"#fff","theme_color":"#fff","display":"standalone","icon":"src/images/icon.png","include_favicon":false,"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","cacheDigest":"8de02dc8ebd99d54fe8f493825d5b012"},
    },{
      plugin: require('../../../node_modules/@whitespace/gatsby-plugin-cookie-consent/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"http://localhost:8000","stripQueryString":true},
    },{
      plugin: require('../../../packages/gatsby-theme-falkenberg/gatsby-browser.js'),
      options: {"plugins":[],"basePath":"/home/vsts/work/1/s/projects/lax.se","fragmentsDir":"/home/vsts/work/1/s/projects/lax.se/src/fragments","i18next":{"defaultLanguage":"sv","languages":["sv","en"]},"wp":{"url":"https://cms.falkenberg.se/salmon","nodesPerFetch":150,"contentTypes":{"page":{}}},"search":{"paths":["sok","en/search"]},"postCss":{"postcssOptions":{"plugins":[null]}},"compileES6Packages":{"modules":["@whitespace/components"]}},
    },{
      plugin: require('../../../node_modules/@whitespace/gatsby-plugin-matomo/gatsby-browser.js'),
      options: {"plugins":[],"routeChangeEventName":false,"trackPageViews":true,"mtmSiteId":"3","mtmHost":"https://matomo-falkenberg.wiznet.se/","includeInDevelopment":true,"mtmDefaultDataVariable":null,"mtmPAQDefaultDataVariable":null,"requireCookieConsent":false},
    }]
