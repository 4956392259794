import PropTypes from "prop-types";
import React from "react";

import EventTeaser from "../../../../components/EventTeaser";
import Teaser from "../../../../components/Teaser";

WPDefaultContentNodeTeaser.propTypes = {
  contentNode: PropTypes.shape({
    contentType: PropTypes.string,
    content: PropTypes.node,
    date: PropTypes.string,
    excerpt: PropTypes.node,
    image: PropTypes.object,
    theme: PropTypes.string,
    title: PropTypes.node,
    url: PropTypes.string,
    taxonomies: PropTypes.arrayOf(PropTypes.object),
    link: PropTypes.object,
  }),
  dateFormat: PropTypes.objectOf(PropTypes.string),
  styles: PropTypes.objectOf(PropTypes.string),
};

export default function WPDefaultContentNodeTeaser({
  contentNode,
  dateFormat = {
    year: "numeric",
    month: "numeric",
    day: "numeric",
  },
  ...restProps
}) {
  const Component = contentNode.contentType === "event" ? EventTeaser : Teaser;
  return (
    <Component
      contentNode={contentNode}
      dateFormat={dateFormat}
      {...restProps}
    />
  );
}
