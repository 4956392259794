// extracted by mini-css-extract-plugin
export var arrow = "Footer-module--arrow--jY+pg";
export var bottom = "Footer-module--bottom--bPyoc";
export var component = "Footer-module--component--SrFmo";
export var copyright = "Footer-module--copyright--0AbAn";
export var flexWrapper = "Footer-module--flexWrapper--KNKLf";
export var footerMenu = "Footer-module--footerMenu--9iP0W";
export var globe = "Footer-module--globe--eclY6";
export var grid = "Footer-module--grid--2EqBg";
export var item = "Footer-module--item--iS6OV";
export var link = "Footer-module--link--5ee32";
export var locale = "Footer-module--locale--Z4uji";
export var logo = "Footer-module--logo--ZUAZW";
export var main = "Footer-module--main--ufzWi";
export var meta = "Footer-module--meta--dGzua";
export var text = "Footer-module--text--isSg6";
export var wrapper = "Footer-module--wrapper--pnnXn";