import { withComponentDefaults } from "@whitespace/components";
import { WPContentNodeTeaser } from "@whitespace/gatsby-theme-wordpress-basic/src/components";
import PropTypes from "prop-types";
import React from "react";

PostsModuleTeaserItem.propTypes = {
  item: PropTypes.object,
};
export default withComponentDefaults(
  PostsModuleTeaserItem,
  "postsModuleTeaserItem",
);

function PostsModuleTeaserItem({ item, ...restProps }) {
  return <WPContentNodeTeaser contentNode={item} {...restProps} />;
}
