import { useLocation } from "@gatsbyjs/reach-router";
import { Icon, TreeMenu } from "@whitespace/components";
import { usePrevious } from "@whitespace/gatsby-hooks";
import { useMenu } from "@whitespace/gatsby-theme-wordpress-basic/src/hooks/menus";
import clsx from "clsx";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import * as defaultStyles from "./HeaderFlyOutMenu.module.css";

HeaderFlyOutMenu.propTypes = {
  styles: PropTypes.objectOf(PropTypes.string),
  className: PropTypes.string,
};

export default function HeaderFlyOutMenu({
  styles = defaultStyles,
  className,
  ...restProps
}) {
  const { t } = useTranslation();
  const location = useLocation();
  const prevLocation = usePrevious(location);
  let menu = useMenu("HAMBURGER");
  let items = menu?.items;
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    if (location !== prevLocation) {
      setExpanded(false);
    }
  }, [location, prevLocation]);

  if (!items) {
    return null;
  }

  const toMunicipalityLinkItem = {
    description: undefined,
    icon: undefined,
    id: "cG9zdDo2MzU4",
    label: t("gotoFalkenbergsKommun"),
    menuParentId: null,
    target: "_blank",
    type: "custom",
    url: "https://kommun.falkenberg.se/",
  };

  items.push(toMunicipalityLinkItem);

  return (
    <div className={clsx(styles.component, className)} {...restProps}>
      <button
        type="button"
        onClick={() => setExpanded(!expanded)}
        className={styles.toggle}
      >
        <Icon name="menu" />
        <span className={styles.label}>{t("menu")}</span>
      </button>
      {expanded && (
        <>
          <div className={styles.backdrop} onClick={() => setExpanded(false)} />
          <TreeMenu
            items={items}
            location={location}
            className={clsx(styles.menu)}
          />
        </>
      )}
    </div>
  );
}
