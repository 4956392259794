import ModularityArea from "@municipio/gatsby-theme-basic/src/components/ModularityArea";
import PageContent from "@whitespace/gatsby-theme-wordpress-basic/src/components/PageContent";
import TextContent from "@whitespace/gatsby-theme-wordpress-basic/src/components/TextContent";
import WPBlocks from "@whitespace/gatsby-theme-wordpress-basic/src/components/WPBlocks";
import { usePageContext } from "@whitespace/gatsby-theme-wordpress-basic/src/hooks";
import clsx from "clsx";
import PropTypes from "prop-types";
import React from "react";

import EventInfo from "../../../components/EventInfo";
import { getMostRelevantEvents } from "../../../hooks/getMostRelevantEvents";

import * as defaultStyles from "./ArticleBody.module.css";

ArticleBody.propTypes = {
  styles: PropTypes.objectOf(PropTypes.string),
  className: PropTypes.string,
};

export default function ArticleBody({
  styles = defaultStyles,
  className,
  ...restProps
}) {
  let {
    contentNode: {
      isFrontPage,
      content: contentHTML,
      contentMedia,
      contentAreaTop,
      blocksJSON,
      acfGroupEvent,
      contentType: {
        node: { name: contentTypeName },
      },
      pageAppearance: { template } = {},
    },
  } = usePageContext();

  const rcrRules = acfGroupEvent?.rcrRules;
  const occasions = acfGroupEvent?.occasions;
  const { eventDatesWithTime } = getMostRelevantEvents(occasions, rcrRules);

  const isEventPage = contentTypeName === "event";
  const isLandingPage = template === "landingPage";

  const visibleContentAreaTopModules = contentAreaTop?.modules.some(
    (m) => !m.hidden,
  );

  return (
    <TextContent className={clsx(styles.component, className)} {...restProps}>
      {blocksJSON ? (
        <>
          <WPBlocks
            blocks={JSON.parse(blocksJSON)}
            contentMedia={contentMedia}
          />
        </>
      ) : (
        <PageContent input={contentHTML} contentMedia={contentMedia}>
          {({ preamble, content }) => (
            <>
              {!isFrontPage && preamble && (
                <div
                  className={clsx(styles.preamble, {
                    [styles.eventStylePreamble]: isEventPage,
                    [styles.landingPageStylePreamble]: isLandingPage,
                  })}
                >
                  {preamble}
                </div>
              )}

              {isEventPage && (
                <EventInfo
                  info={acfGroupEvent}
                  eventDatesWithTime={eventDatesWithTime}
                />
              )}

              {visibleContentAreaTopModules && (
                <ModularityArea
                  area={contentAreaTop}
                  className={clsx(styles.contentAreaTop)}
                />
              )}

              {!isFrontPage && content}
            </>
          )}
        </PageContent>
      )}
    </TextContent>
  );
}
