import { getMostRelevantDate } from "@municipio/gatsby-theme-basic/src/utils";
import {
  Teaser as TeaserWrapper,
  TeaserContent,
  TeaserTitle,
  TeaserMedia,
  TeaserMeta,
  Link,
} from "@whitespace/components";
import { usePageContext } from "@whitespace/gatsby-theme-wordpress-basic/src/hooks";
import { useHTMLProcessor } from "@whitespace/gatsby-theme-wordpress-basic/src/hooks/html-processor";
import clsx from "clsx";
import format from "date-fns/format";
import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";

import CalendarBadge from "./CalendarBadge";
import EventTeaserMeta from "./EventTeaserMeta";
import * as defaultStyles from "./Teaser.module.css";

EventTeaser.propTypes = {
  className: PropTypes.string,
  styles: PropTypes.objectOf(PropTypes.string),
  contentNode: PropTypes.shape({
    contentType: PropTypes.string,
    content: PropTypes.node,
    date: PropTypes.string,
    excerpt: PropTypes.node,
    image: PropTypes.object,
    theme: PropTypes.string,
    title: PropTypes.node,
    url: PropTypes.string,
    text: PropTypes.string,
    taxonomies: PropTypes.arrayOf(PropTypes.object),
    eventCategories: PropTypes.shape({
      nodes: PropTypes.arrayOf(PropTypes.string),
    }),
    tags: PropTypes.arrayOf(PropTypes.string),
    link: PropTypes.object,
    acfGroupEvent: PropTypes.objectOf(PropTypes.string),
    location: PropTypes.object,
    eventsToBeNumber: PropTypes.number,
    dateWithTime: PropTypes.string,
  }),
  gridView: PropTypes.bool,
};

export default function EventTeaser({
  as: TeaserWrapper = "div",
  className,
  styles = defaultStyles,
  contentNode,
  gridView,
  ...restProps
}) {
  const { t } = useTranslation();

  const { contentNode: pageContext } = usePageContext();
  const name = pageContext?.contentType?.node?.name;
  const listView = name !== "page" && !gridView;

  const {
    excerpt,
    image,
    title,
    url,
    link,
    text,
    tags,
    acfGroupEvent: { bookingLink = [] },
    location: { name: locationName },
    eventCategories,
    eventsToBeNumber,
    date,
    dateWithTime,
  } = contentNode;

  const { stripHTML } = useHTMLProcessor();
  const excerptBackup = stripHTML(text);
  const target = link?.target;
  const linkTitle = bookingLink
    ? t("Buy ticket")
    : link?.title || t("Read more");

  return (
    <TeaserWrapper
      className={clsx(
        className,
        styles.component,
        styles.event,
        listView && styles.eventList,
      )}
      link={{ url, target }}
      styles={styles}
      {...restProps}
    >
      {image && !listView && (
        <TeaserMedia image={image} styles={styles} aspectRatio={16 / 10} />
      )}
      <TeaserContent styles={styles}>
        <div className={clsx(styles.contentInner)}>
          <CalendarBadge
            futureEventsCount={eventsToBeNumber}
            date={dateWithTime}
            locale={"sv"}
          />
          <header className={styles.header}>
            <TeaserTitle>{title}</TeaserTitle>
            <TeaserMeta styles={styles}>
              <EventTeaserMeta date={dateWithTime} location={locationName} />
            </TeaserMeta>
          </header>
        </div>
        {(excerpt || excerptBackup) && (
          <p className={clsx(styles.excerpt, listView && styles.space)}>
            {excerpt || excerptBackup}
          </p>
        )}
        {url && (
          <Link to={url} className={clsx(styles.cta, listView && styles.space)}>
            {linkTitle}
          </Link>
        )}
      </TeaserContent>
    </TeaserWrapper>
  );
}
