import { Section } from "@jfrk/react-heading-levels";
import ModularityArea from "@municipio/gatsby-theme-basic/src/components/ModularityArea";
import { withComponentDefaults } from "@whitespace/components";
import ArticleBody from "@whitespace/gatsby-theme-wordpress-basic/src/components/ArticleBody";
import ArticleChildPageNav from "@whitespace/gatsby-theme-wordpress-basic/src/components/ArticleChildPageNav";
import ArticleTitle from "@whitespace/gatsby-theme-wordpress-basic/src/components/ArticleTitle";
import { layout } from "@whitespace/gatsby-theme-wordpress-basic/src/foundation";
import { usePageContext } from "@whitespace/gatsby-theme-wordpress-basic/src/hooks";
import clsx from "clsx";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import ArticleLocation from "../../../components/ArticleLocation";
import EventPageHeader from "../../../components/EventPageHeader";
import LeafletMap from "../../../components/LeafletMap";
import RelatedLocationPages from "../../../components/RelatedLocationPages";
import Shortcuts from "../../../components/Shortcuts";

import * as defaultStyles from "./Article.module.css";
import ArticleFeaturedImage from "./ArticleFeaturedImage";

Article.propTypes = {
  className: PropTypes.string,
  styles: PropTypes.objectOf(PropTypes.string),
};

export default withComponentDefaults(Article, "article");

function Article({
  className,
  styles = defaultStyles,
  // displayMode,
  ...restProps
}) {
  let {
    contentNode: {
      isFrontPage,
      sliderArea,
      contentArea,
      hideSubpagesNavigation: { hideBoxNavigation = false } = {},
      location: { active: isLocation, address },
      contentType: {
        node: { name: contentTypeName },
      },
      pageAppearance: { template } = {},
    },
    isPreview,
    language
  } = usePageContext();

  const { i18n } = useTranslation();
  useEffect(() => {
    if (isPreview && language) {
      i18n.changeLanguage(language);
    }
  }, [isPreview, i18n, language]);

  const isLandingPage = template === "landingPage";
  const isEventPage = contentTypeName === "event";

  const visibleSliderAreaModules = sliderArea?.modules.some((m) => !m.hidden);
  const visibleContentAreaModules = contentArea?.modules.some((m) => !m.hidden);

  return (
    <article
      className={clsx(layout.component, layout.componentWidthFull, className)}
      {...restProps}
    >
      {visibleSliderAreaModules && <ModularityArea area={sliderArea} />}

      <ArticleFeaturedImage />

      {isFrontPage && <Shortcuts />}

      {isLandingPage && !hideBoxNavigation && (
        <ArticleChildPageNav className={styles.childPages} />
      )}

      {!isFrontPage && !isLocation && !isEventPage && (
        <ArticleTitle className={styles.title} />
      )}

      {isEventPage && <EventPageHeader />}

      {isLocation && !isEventPage && <ArticleLocation />}

      <Section>
        <ArticleBody />

        {isEventPage && isLocation && (
          <div className={styles.mapWrapper}>
            <LeafletMap address={address} isEventPage={isEventPage} />
          </div>
        )}

        {visibleContentAreaModules && (
          <ModularityArea
            area={contentArea}
            className={clsx(styles.contentArea)}
          />
        )}

        {isLocation && !isEventPage && <RelatedLocationPages />}
      </Section>
    </article>
  );
}
