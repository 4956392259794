// extracted by mini-css-extract-plugin
export var activityWrapper = "HtmlModule-module--activityWrapper--FPC8F";
export var component = "HtmlModule-module--component--Z6d96";
export var form = "HtmlModule-module--form---TPz1";
export var formInput = "HtmlModule-module--formInput--xKUWQ";
export var image = "HtmlModule-module--image--zF5kU";
export var imageWrapper = "HtmlModule-module--imageWrapper--OfqgJ";
export var isCompact = "HtmlModule-module--isCompact--FXUos";
export var overlay = "HtmlModule-module--overlay--NoWyZ";
export var textsWrapper = "HtmlModule-module--textsWrapper--NVKb5";
export var title = "HtmlModule-module--title--H4jWc";