import { useStaticQuery, graphql } from "gatsby";

export default function useThemeSettings() {
  return useStaticQuery(graphql`
    query ThemeSettingsCustom {
      wp {
        acfOptionsThemeOptions {
          logotype {
            logotype {
              fileContent
            }
            logotypeNegative {
              fileContent
            }
          }
        }
      }
    }
  `).wp.acfOptionsThemeOptions;
}
