import { Link, withComponentDefaults } from "@whitespace/components";
import { HTML } from "@whitespace/gatsby-theme-wordpress-basic/src/components";
import * as defaultStyles from "@whitespace/gatsby-theme-wordpress-basic/src/components/HeaderLogo.module.css";
import clsx from "clsx";
import { graphql, useStaticQuery } from "gatsby";
import PropTypes from "prop-types";
import React from "react";

import useThemeSettings from "../../../hooks/theme";


HeaderLogo.propTypes = {
  color: PropTypes.string,
  align: PropTypes.string,
  "aria-label": PropTypes.string,
  className: PropTypes.string,
  components: PropTypes.objectOf(PropTypes.elementType),
  linkProps: PropTypes.object,
  linkTo: PropTypes.any,
  styles: PropTypes.objectOf(PropTypes.string),
};

function DefaultLogo({ ...restProps }) {
  const title = useStaticQuery(graphql`
    query siteTitleQueryAndSiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `).site?.siteMetadata?.title;

  const {
    logotype: { logotype },
  } = useThemeSettings();
  if (logotype && logotype.fileContent) {
    return <HTML>{logotype.fileContent}</HTML>;
  }

  return <span {...restProps}>{title}</span>;
}

export default withComponentDefaults(HeaderLogo, "headerLogo");

function HeaderLogo({
  color = "currentColor",
  align = "left",
  "aria-label": ariaLabel,
  className,
  components: { Logo = DefaultLogo } = {
    Logo: DefaultLogo,
  },
  linkProps: { ...linkRestProps } = {},
  linkTo,
  styles = defaultStyles,
  ...restProps
}) {
  return (
    <div className={clsx(styles.component, className)} {...restProps}>
      <Link
        to={linkTo}
        className={clsx(styles.link)}
        aria-label={ariaLabel}
        {...linkRestProps}
      >
        <Logo align={align} color={color} className={styles.logo} />
      </Link>
    </div>
  );
}
