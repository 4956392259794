import { css } from "@emotion/react";
import CookieConsent from "@whitespace/gatsby-plugin-cookie-consent/src/components";
import useCookieConsentSettings from "@whitespace/gatsby-plugin-cookie-consent/src/hooks/useCookieConsentSettings";
import Footer from "@whitespace/gatsby-theme-wordpress-basic/src/components/Footer";
import Header from "@whitespace/gatsby-theme-wordpress-basic/src/components/Header";
import { usePageContext } from "@whitespace/gatsby-theme-wordpress-basic/src/hooks/page-context";
import clsx from "clsx";
import { graphql, useStaticQuery } from "gatsby";
import { kebabCase } from "lodash";
import PropTypes from "prop-types";
import React from "react";
import Helmet from "react-helmet";

import * as defaultStyles from "./SiteLayout.module.css";

SiteLayout.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  styles: PropTypes.objectOf(PropTypes.string),
};

export default function SiteLayout({
  children,
  className,
  styles = defaultStyles,
}) {
  const {
    contentNode: {
      title,
      isFrontPage,
      pageAppearance: { template } = {},
      pageThemeColor: { theme = [] } = {},
      seo,
    } = {},
  } = usePageContext();

  const { position, active, strings } = useCookieConsentSettings();

  const { site } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  const themeProp = theme ? kebabCase(theme) : null;

  return (
    <>
      <Helmet
        title={seo?.title || title}
        titleTemplate={
          isFrontPage
            ? site.siteMetadata.title
            : `%s - ${site.siteMetadata.title}`
        }
        defaultTitle={site.siteMetadata.title}
        meta={[
          {
            name: "description",
            content: seo?.metaDesc || "",
          },
          {
            name: "robots",
            content: seo?.metaRobotsNoindex ? "noindex" : "index",
          },
          {
            property: "og:title",
            content: seo?.opengraphTitle || "",
          },
          {
            property: "og:description",
            content: seo?.opengraphDescription || "",
          },
          {
            property: "og:image",
            content: seo?.opengraphImage?.mediaItemUrl || "",
          },
          {
            property: "og:type",
            content: seo?.opengraphType || "website",
          },
          {
            name: "twitter:title",
            content: seo?.twitterTitle || "",
          },
          {
            name: "twitter:description",
            content: seo?.twitterDescription || "",
          },
          {
            name: "twitter:image",
            content: seo?.twitterImage?.mediaItemUrl || "",
          },
          {
            name: "theme-color",
            content: "#fff",
            media: "(prefers-color-scheme: light)",
          },
        ]}
      />
      <div
        css={
          themeProp &&
          css`
            --page-theme-background: var(--brand-color-${themeProp});
          `
        }
        className={clsx(styles.component, className)}
      >
        <CookieConsent
          position={position}
          cookieConsentSettings={strings}
          active={active}
        />
        <Header />
        <main className={styles.main} id="main">
          {children}
        </main>
        <Footer />
      </div>
    </>
  );
}
