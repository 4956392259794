/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import styled from "@emotion/styled";
import { useID } from "@whitespace/components";
import { useFormikContext, Field } from "formik";
import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import "react-datepicker/dist/react-datepicker-cssmodules.css";

SearchFormTimeSelectField.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
};

const StyledField = styled(Field)`
  grid-column: 1;
  grid-row: 1;
  z-index: ${(props) => (props.hideLabel ? 3 : 1)};
  &:focus {
    z-index: 3;
  }
  border-color: var(--select-field-control-border-color, #dfe1e4);
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  cursor: default;
  min-height: 38px;
  outline: 0 !important;
  transition: all 100ms;
  box-sizing: border-box;
  color: var(--select-field-control-color, #3d4148);
  font-size: var(--select-field-font-size, 0.875rem);
  font-weight: var(--select-field-font-weight, 400);
  padding: 0 0.25rem;
  @media (max-width: 32rem) {
    width: 280px;
    height: 40px;
  }
`;

const StyledLabel = styled.label`
  display: flex;
  align-items: center;
  grid-column: 1;
  grid-row: 1;
  z-index: 2;
  border-color: transparent;
  border-style: solid;
  border-width: 1px;
  outline: 0 !important;
  transition: all 100ms;
  box-sizing: border-box;
  color: var(--select-field-control-color, #3d4148);
  font-size: var(--select-field-font-size, 0.875rem);
  font-weight: var(--select-field-font-weight, 400);
  padding: 0 0.5rem;
  pointer-events: none;
  margin: 2px;
  background: white;
  @media (max-width: 32rem) {
    width: 150px;
    height: 35px;
  }
`;

export default function SearchFormTimeSelectField({ id, name }) {
  const { setFieldValue, values, submitForm } = useFormikContext();
  const generateID = useID();
  const { t } = useTranslation();
  id = id || generateID(name);

  return (
    <>
      <div
        css={css`
          display: grid;
        `}
      >
        <StyledLabel htmlFor={`${id}-from`}>
          {t(["datePicker.placeholder.from", "From date"])}
        </StyledLabel>
        <StyledField
          type="date"
          name={`${name}:gte`}
          id={`${id}-from`}
          onChange={(event) => {
            setFieldValue(`${name}:gte`, event.target.value);
            setTimeout(submitForm, 0);
          }}
          hideLabel={values[`${name}:gte`]}
        />
      </div>
      <div
        css={css`
          display: grid;
        `}
      >
        <StyledLabel htmlFor={`${id}-to`}>
          {t(["datePicker.placeholder.to", "To date"])}
        </StyledLabel>
        <StyledField
          type="date"
          name={`${name}:lte`}
          id={`${id}-to`}
          onChange={(event) => {
            setFieldValue(`${name}:lte`, event.target.value);
            setTimeout(submitForm, 0);
          }}
          hideLabel={values[`${name}:lte`]}
        />
      </div>
    </>
  );
}
