// extracted by mini-css-extract-plugin
export var activityDescription = "ActivitiesModule-module--activityDescription--xTHQl";
export var activityTitle = "ActivitiesModule-module--activityTitle--YaswY";
export var activityWrapper = "ActivitiesModule-module--activityWrapper--vvzGy";
export var component = "ActivitiesModule-module--component--BhNtU";
export var form = "ActivitiesModule-module--form--ogLTI";
export var formInput = "ActivitiesModule-module--formInput--cEpYH";
export var image = "ActivitiesModule-module--image--BGKhA";
export var imageWrapper = "ActivitiesModule-module--imageWrapper--uO769";
export var isCompact = "ActivitiesModule-module--isCompact--J3KI3";
export var overlay = "ActivitiesModule-module--overlay--bwPGt";
export var randomizeButton = "ActivitiesModule-module--randomizeButton--bKYSr";
export var readMoreButton = "ActivitiesModule-module--readMoreButton--86zNC";
export var textsWrapper = "ActivitiesModule-module--textsWrapper--H3DuX";
export var title = "ActivitiesModule-module--title--McERy";