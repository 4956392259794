import ArticleTitle from "@whitespace/gatsby-theme-wordpress-basic/src/components/ArticleTitle";
import { layout } from "@whitespace/gatsby-theme-wordpress-basic/src/foundation";
import { usePageContext } from "@whitespace/gatsby-theme-wordpress-basic/src/hooks";
import clsx from "clsx";
import PropTypes from "prop-types";
import React from "react";

import * as defaultStyles from "./EventPageHeader.module.css";

EventPageHeader.propTypes = {
  styles: PropTypes.objectOf(PropTypes.string),
  className: PropTypes.string,
  menuName: PropTypes.string,
};

export default function EventPageHeader({
  styles = defaultStyles,
  className,
  ...restProps
}) {
  const {
    contentNode: {
      location: {
        name,
        address: { city, street, zip },
      },
    },
  } = usePageContext();

  return (
    <header
      className={clsx(styles.component, layout.component, styles.header)}
      {...restProps}
    >
      <ArticleTitle className={styles.title} />
      <p className={styles.address}>
        {name && <><span className={styles.location}>{name}</span> - </>}
        {street && <span>{street}, </span>}
        {city && <span>{city} </span>}
        {zip && <span>{zip}</span>}
      </p>
    </header>
  );
}
