import "./article.props.css";
import "./button.props.css";
import "./box-navigation.props.css";
import "./Card.props.css";
import "./CalendarBadge.props.css";
import "./colors.props.css";
import "./cookie-consent.props.css";
import "./Grid.props.css";
import "./fonts.css";
import "./header.props.css";
import "./index.css";
import "./index.props.css";
import "./notice-module.props.css";
import "./post-list.props.css";
import "./search.props.css";
import "./skipTo.props.css";
import "./teaser.props.css";
import "./typography.props.css";

const theme = {
  flow: "clamp(1.00rem, calc(0.86rem + 0.71vw), 1.50rem)",
  heading1: "clamp(2.00rem, calc(1.43rem + 2.86vw), 4.00rem)",
  heading2: "clamp(2.00rem, calc(1.71rem + 1.43vw), 3.00rem)",
  heading3: "clamp(1.50rem, calc(1.21rem + 1.43vw), 2.50rem)",
  heading4: "clamp(1.20rem, calc(1.03rem + 0.86vw), 1.80rem)",
  heading5: "clamp(1.13rem, calc(1.09rem + 0.18vw), 1.25rem)",
  baseFontFamily: "Montserrat, system-ui, sans-serif",
  footerFontFamily: "Montserrat, system-ui, sans-serif",
  headingFontFamily: "BodoniStd, system-ui, serif",
  displayFontFamily: "FalkenbergScript, serif",
  posterFontFamily: "BodoniStdPoster, serif",
  layoutPadding: "clamp(1.00rem, calc(0.71rem + 1.43vw), 2.00rem)",
  boxShadowHero:
    "0 100px 80px rgba(0, 0, 0, 0.07), 0px 41.7776px 33.4221px rgba(0, 0, 0, 0.05), 0px 22px 18px rgba(0, 0, 0, 0.0), 0px 12px 10.0172px rgba(0, 0, 0, 0.035), 0px 6px 5.32008px rgba(0, 0, 0, 0.03), 0px 2px 2px rgba(0, 0, 0, 0.019)",
  pageNarrowTitleWidth: "56.5rem",
  pageNarrowPadding: "0 1rem",
  linkColor: "#E87454",
  linkTextDecoration: "underline",
  imageCaptionColor: "var(--color-base-text-light)",
  textModuleTitleFontSize: "var(--heading-2)",
  boxBorderRadius: "0",
  boxBorder: "1px solid var(--color-base-divider)",
  boxBackground: "transparent",
  boxPadding: "clamp(1.00rem, calc(0.71rem + 1.43vw), 2.00rem)",
  boxColor: "inherit",
  boxFontSize: "100%",
  tableBackground: "transparent",
  tableTitleFontFamily: "var(--heading-font-family)",
  tableTitleFontSize: "var(--heading-2)",
  tableTitleFontStyle: "inherit",
  tableTitleFontWeight: "400",
  tableHeadColor: "inherit",
  tableHeadBorderBottom: "2px solid",
  tableHeadBorderBottomColor: "var(--color-base-divider)",
  tableHeadFontSize: "inherit",
  tableHeadFontWeight: "600",
  tableHeadFontStyle: "inherit",
  tableHeadAlign: "left",
  tableHeadBackground: "transparent",
  tableCellBackground: "transparent",
  tableCellFontSize: "inherit",
  tableCellFontWeight: "inherit",
  tableCellFontStyle: "inherit",
  tableCellPadding:
    "clamp(0.25rem, calc(0.15rem + 0.49vw), 0.50rem) clamp(0.50rem, calc(0.30rem + 0.98vw), 1.00rem)",
  tableCellBorder: "none",
  tableCellBorderColor: "#d4d4d8",
  treeMenu: {
    link: {
      color: "#000",
      active: {
        background: "var(--color-footer-background)",
      },
      hover: {
        background: "#EEEEEE",
        color: "#000000",
      },
    },
  },
  moduleWrapperHeaderRuleHeight: "0",
  newsletter: {
    titleFontWeight: "400",
    titleColor: "var(--color-base-text-default)",
    backgroundColor: "rgba(198, 203, 194, 0.4)",
    buttonBackground: "var(--button-background)",
    buttonColor: "var(--button-color)",
    border: "none",
    formInputBorderRadius: "2px",
    formInputBackgroundColor: "white",
  },
};

export default theme;
