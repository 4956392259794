import useTaxonomies from "@municipio/gatsby-theme-basic/src/hooks/useTaxonomies";
import React from "react";

import { getMostRelevantEvents } from "../../../hooks/getMostRelevantEvents";

function visibleFields(item) {
  return {
    showDate: item?.includes("date"),
    showImage: item?.includes("image"),
    showExcerpt: item?.includes("excerpt"),
  };
}

export default function normalizePostsModuleItems(
  { modPostsDataSource, contentNodes, modPostsDataDisplay },
  { HTML, stripHTML } = {},
) {
  if (!modPostsDataSource?.postsDataSource) {
    return [];
  }
  const { showDate, showImage, showExcerpt } = visibleFields(
    modPostsDataDisplay.postsFields,
  );
  const eventItemsType =
    modPostsDataSource?.postsDataPostType?.name === "event";

  switch (modPostsDataSource.postsDataSource) {
    case "input":
      return (modPostsDataSource.data || []).map(
        ({ postContentMedia, ...item }) => {
          let processedContent = (
            <HTML contentMedia={postContentMedia}>{item.postContent}</HTML>
          );
          return {
            ...item,
            title: item.postTitle,
            url: item.link?.url || item.permalink,
            excerpt: showExcerpt && stripHTML(item.postContent),
            content: showExcerpt && processedContent,
          };
        },
      );

    default: {
      let itemsArr = contentNodes?.nodes || [];

      let items = itemsArr.filter(Boolean).map(({ contentMedia, ...item }) => {
        let processedContent = (
          <HTML contentMedia={contentMedia}>{item.content}</HTML>
        );

        let contentType = item?.contentType?.node?.name;
        let dates, date, eventsToBeNumber, datesWithTime, dateWithTime;
        if (contentType !== "page" && item?.acfGroupEvent) {
          const {
            acfGroupEvent: { rcrRules = [], occasions = [] },
          } = item;

          const {
            eventDates,
            eventDate,
            futureEventsCount,
            eventDatesWithTime,
            eventDateWithTime,
          } = getMostRelevantEvents(occasions, rcrRules);

          dates = eventDates;
          date = eventDate;
          eventsToBeNumber = futureEventsCount;
          datesWithTime = eventDatesWithTime;
          dateWithTime = eventDateWithTime;
        }

        return {
          ...item,
          contentType: item.contentType?.node?.name,
          title: item.title,
          dateGmt: showDate && item.dateGmt,
          date: showDate && (date || item.dateGmt),
          url: item.uri,
          excerpt:
            showExcerpt &&
            (item.description ? item.description : stripHTML(item.content)),
          image: showImage && item.featuredImage?.node,
          content: showExcerpt && processedContent,
          taxonomies: useTaxonomies(
            { ...item.tags?.nodes, ...item.categories?.nodes },
            item.contentType?.node?.name,
          ),
          dates,
          eventsToBeNumber,
          datesWithTime,
          dateWithTime,
        };
      });

      let itemsToSlice =
        modPostsDataSource.postsCount >= 0
          ? modPostsDataSource.postsCount
          : itemsArr.length;

      if (eventItemsType) {
        return items
          ?.filter((item) => item.date > new Date())
          ?.sort((a,b) => a["date"] - b["date"])
          ?.slice(0, itemsToSlice);
      }

      return items?.slice(0, itemsToSlice);
    }
  }
}
